.DescriptionContainer{
    overflow-y: scroll;
    height: 20vh;
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.DescriptionContainerShort{
    overflow-y: scroll;
    height: 10vh;
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.CustomCard{
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
}

.CustomAccordionContent{
    background: white;
}

.CustomAccordionHeaderText{
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}