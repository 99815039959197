.GeneralitaPanel{
    padding-top: 10px;
    padding-right: 10px;
    border-bottom-width: 10px;
    border-left-width: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    text-align: left;
    background: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 40vh;
    gap: 4px;
}

.GeneralitaPanelSmall{
    padding-top: 10px;
    padding-right: 10px;
    border-bottom-width: 10px;
    border-left-width: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    text-align: left;
    background: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 20vh;
    gap: 4px;
}


.DescriptionContainer{
    overflow-y: scroll;
    height: 20vh;
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.LeftAlign{
    text-align: left;
}

.CustomCard{
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
}

.CustomAccordionContent{
    background: white;
}

.CustomAccordionHeaderText{
    background: rgba(178, 206, 223, 0.75);
    border-radius: 19px;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
}

.BottomMargin{
    margin-bottom: 10px;
}