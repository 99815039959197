.Wizard{
    padding-top: 40px;
    text-align: center;
}

.LeftAlign{
    text-align: left;
}

.BottomMargin{
    margin-bottom: 10px;
}